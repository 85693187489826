import React, { useState } from 'react';
import { GET_RECOVER } from 'helpers/request';
import useAuth from 'auth/useAuth';

import { useForm } from 'hooks/useForm';

import logo from '../images/logo.jpg';

import '../styles/LoginScreen.css';

const BASE_URL = process.env.REACT_APP_ADMINISTRATION_BASE_URL;

export default function Login() {
  const auth = useAuth();

  const [values, handleInputChange] = useForm({
    email:
      `${BASE_URL}` === 'https://flex.test/administration/'
        ? 'albornoz.antonio@isc'
        : '',
    password:
      `${BASE_URL}` === 'https://flex.test/administration/' ? 'Flex12345-' : ''
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [recover, setRecover] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ruc, setRuc] = useState('');

  const handleRuc = ({ target }) => {
    setRuc(target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    const res = await auth.login(values.email, values.password);

    if (res) {
      setLoading(false);
      return setError(res);
    }
  };

  const handleRecover = async (e) => {
    e.preventDefault();
    setError(null);

    if (ruc.length <= 10)
      return setError('El campo requiere un mínimo de 13 caracteres');
    if (ruc.length >= 15)
      return setError('El campo requiere un máximo de 20 caracteres');

    await GET_RECOVER(ruc, setSuccess, setError, setRecover);
  };

  return (
    <div className="login-screen d-flex align-items-center justify-content-center">
      <form
        onSubmit={!recover ? handleSubmit : handleRecover}
        className="login-card card p-3 p-xl-5 shadow bg-body"
      >
        <div className="w-100 mb-4 d-flex justify-content-center">
          <img src={logo} alt="Flex logo" width="200" />
        </div>

        {error && (
          <div
            className="alert alert-danger w-100 mb-4 text-center"
            role="alert"
          >
            {error}
          </div>
        )}

        {success && (
          <div
            className="alert alert-success w-100 mb-4 text-center"
            role="alert"
          >
            {success}
          </div>
        )}

        {recover && (
          <div className="form-floating mb-3">
            <input
              type="number"
              className={`form-control ${error && 'is-invalid'}`}
              placeholder="Ingrese su RUC o RISE"
              id="rucLogin"
              name="ruc"
              value={ruc}
              onChange={handleRuc}
              required
            />
            <label htmlFor="emailLogin">Ingrese su RUC o RISE</label>
          </div>
        )}

        {!recover && (
          <>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Ingresa tu usuario"
                id="emailLogin"
                name="email"
                value={values.email}
                onChange={handleInputChange}
                readOnly={loading || auth.refreshing ? true : false}
              />
              <label htmlFor="emailLogin">Usuario</label>
            </div>

            <div className="form-floating mb-0">
              <input
                type="password"
                className="form-control"
                id="passLogin"
                placeholder="Contraseña"
                name="password"
                value={values.password}
                onChange={handleInputChange}
                readOnly={loading || auth.refreshing ? true : false}
              />
              <label htmlFor="passLogin">Contraseña</label>
            </div>
          </>
        )}

        {!recover && !auth.refreshing && (
          <button
            onClick={() => setRecover(true)}
            type="button"
            className="pass_recover text-info btn mt-2"
            style={{ cursor: 'pointer' }}
          >
            Recuperar contraseñaaa
          </button>
        )}

        <button
          type="submit"
          className="btn btn-danger mt-4"
          disabled={loading || auth.refreshing ? true : false}
        >
          {loading || auth.refreshing ? (
            <div className="d-flex align-items-center justify-content-center">
              <div
                className="spinner-border text-dark"
                role="status"
                style={{ width: '1.5rem', height: '1.5rem' }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : recover ? (
            'Obtener contraseña'
          ) : (
            'Ingresar'
          )}
        </button>
      </form>
    </div>
  );
}
