import { useEffect, useState } from "react";
import { adminUrl, headerToken } from "api/api";
import { PDF_DWNLD_GET } from "helpers/request";

export default function useSituacionDoc() {
  const [loading, setLoading] = useState(false);
  const [auditorBusiness, setAuditorBusiness] = useState([]);
  const [businessSituation, setBusinessSituation] = useState({
    id: "",
    data: [],
  });
  const [categoryData, setCategoryData] = useState(null);

  const getBusiness = async () => {
    setLoading(true);

    try {
      const res = await adminUrl.get("auditar", headerToken());
      setAuditorBusiness(
        res.data.datos.map(({ bus_int_id, bus_text_name }) => ({
          value: bus_int_id,
          name: bus_text_name,
        }))
      );

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getBusinessInfo = async (id) => {
    setLoading(true);

    setCategoryData(null);

    try {
      const res = await adminUrl.get(`recaudit/${id}`, headerToken());

      let newArr;

      newArr = res.data.infotipos.map((item) => ({
        ...item,
        groups: res.data.inforecords.filter(
          (rec) => rec.typ_int_id === item.typ_int_id
        ),
      }));

      setBusinessSituation({ id: id, data: newArr });

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getCategoryInfo = async (id) => {
    setLoading(true);

    try {
      const res = await adminUrl.get(
        `situacion_documental/${id}`,
        headerToken()
      );

      setCategoryData(res.data);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleBusinessChange = ({ target }) => {
    getBusinessInfo(target.value);

    setCategoryData(null);
  };

  const handleGoBack = () => {
    setCategoryData(null);

    getBusinessInfo(businessSituation.id);
  };

  const handleDownload = () => {
    PDF_DWNLD_GET(
      "reports",
      `auditoria-${businessSituation.id}.xlsx`,
      businessSituation.id
    );
  };

  useEffect(() => {
    getBusiness();
  }, []);

  return {
    loading,
    auditorBusiness,
    handleBusinessChange,
    businessSituation,
    categoryData,
    getCategoryInfo,
    handleGoBack,
    handleDownload,
  };
}

// solo puede auditar un doc aprobado

// no puede borrar docs
// modal aprobar
// con 4 botones, cerrar, conforme, inconfroem, obs

// null no hay que auditar
// 0 auditar (pendiente)
// 1-2-3 solo ver
