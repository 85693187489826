import React, { useState } from "react";

import RecordRow from "components/situ-doc-components/RecordRow";
import ValidationTable from "./ValidationTable";
import AuditTable from "./AuditTable";

export default function SituDocItemCategory({ data, goBack }) {
  const [registros, setRegistros] = useState(data.registros);

  return (
    <div className="w-100 d-flex flex-column align-items-center">
      <button
        onClick={goBack}
        type="button"
        className="btn btn-outline-secondary align-self-end align-self-lg-start d-flex align-items-center mb-3"
      >
        <div className="fas fa-sort-up fa-rotate-270 fa-lg"></div>
        Regresar
      </button>

      <h4 className="mb-0 mt-3 w-100 text-center">
        {data.categorias?.cat_text_name}
      </h4>

      <p className="mt-2 mb-4 text-center">
        {data.categorias?.cat_text_description}
      </p>

      {registros.length > 0 ? (
        registros.map((item) => (
          <RecordRow
            key={item.rec_int_id}
            item={item}
            setRegistros={setRegistros}
          />
        ))
      ) : (
        <p className="w-100 mb-0 text-center">No hay registros previos</p>
      )}

      <div className="d-flex gap-5 mb-3">
        <ValidationTable />
        <AuditTable />
      </div>
    </div>
  );
}
