import React from "react";

export default function SituDocItemRow({ item, onClick }) {
  const statusColorTable = {
    0: "btn-primary",
    1: "btn-success",
    2: "btn-danger",
    3: "btn-warning",
  };

  const statusTextTable = {
    0: "Pendiente",
    1: "Conforme",
    2: "No conforme",
    3: "Observaciones",
  };

  return (
    <div className="w-100 d-flex align-items-center border px-3 py-1">
      <div className="w-75">
        <p className="mb-0 w-100 text-start text-truncate">
          {item.cat_text_name}
        </p>
      </div>

      <div className="d-flex justify-content-center w-25">
        {item.conformidad && (
          <button
            onClick={() => onClick(item.cat_int_id)}
            type="button"
            className={`btn btn-sm ${
              statusColorTable[item.conformidad.split("|")[1]]
            }`}
          >
            {statusTextTable[item.conformidad.split("|")[1]]}
          </button>
        )}

        {!item.conformidad && (
          <button
            type="button"
            className="btn btn-sm btn-secondary"
            readOnly
            style={{ pointerEvents: "none" }}
          >
            Sin documentos
          </button>
        )}
      </div>
    </div>
  );
}
