import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import RouterData from 'routes/RouterData';

import CollapseMenu from 'components/layout/CollapseMenu';
import UserCard from 'components/layout/UserCard';
import useAuth from 'auth/useAuth';

export default function MobileSideBar({ handleLogout }) {
  const auth = useAuth();

  const closeMenuButtonRef = useRef();

  const handelClose = () => {
    closeMenuButtonRef.current.click();
  };

  return (
    <div
      className="offcanvas offcanvas-end d-lg-none"
      tabIndex="-1"
      id="MobileSideBar"
      aria-labelledby="MobileSideMenuTitle"
    >
      <div className="offcanvas-header border-bottom">
        <h5
          className="text-center text-uppercase fs-4 mb-0"
          id="MobileSideMenuTitle"
        >
          Ecloflex
        </h5>

        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          ref={closeMenuButtonRef}
        ></button>
      </div>

      <div className="offcanvas-body">
        <div className="border p-2 mb-4">
          <UserCard handleLogout={handleLogout} />
          <p className="fw-bold text-center mt-2">
            La contraseña expira en {auth.user?.userData.passexp} días
          </p>
        </div>

        {RouterData.map((group, index) => (
          <div
            key={index}
            className="border mb-2 d-flex flex-column align-items-start justify-content-center overflow-hidden"
          >
            {group.map((item) =>
              item.link ? (
                <Link
                  onClick={handelClose}
                  className="nav-link w-100"
                  key={item.link}
                  to={item.link}
                >
                  {item.name}
                </Link>
              ) : (
                <CollapseMenu
                  key={item.name}
                  name={item.name}
                  childrens={item.childrens}
                  onClick={handelClose}
                />
              )
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
