import React from "react";

import useSituacionDoc from "hooks/useSituacionDoc";

import SituDocItemCategory from "components/situ-doc-components/SituDocItemCategory";
import SituDocItemRow from "components/situ-doc-components/SituDocItemRow";
import FullWidthContainer from "components/layout/FullWidthContainer";
import { DefaultSelect } from "templates/input";

export default function SituacionDocumental() {
  const {
    loading,
    auditorBusiness,
    handleBusinessChange,
    businessSituation,
    categoryData,
    getCategoryInfo,
    handleGoBack,
    handleDownload,
  } = useSituacionDoc();

  return (
    <FullWidthContainer>
      <h3 className="text-center mb-5">Situación documental</h3>

      {!loading && (
        <DefaultSelect
          label="Empresas"
          inputName="bussinessId"
          placeholder="Selecciona una opción"
          defaultValue={businessSituation.id}
          onChange={handleBusinessChange}
          options={auditorBusiness}
        />
      )}

      {!categoryData && !loading && (
        <div className="d-flex flex-column w-100 mt-2">
          {businessSituation.data.map((group) => {
            if (group.groups.length > 0) {
              return (
                <div key={group.typ_int_id} className="mb-5">
                  <Headers title={group.typ_text_name} />
                  {group.groups.map((item) => (
                    <SituDocItemRow
                      key={item.cat_int_id}
                      item={item}
                      onClick={getCategoryInfo}
                    />
                  ))}
                </div>
              );
            }

            return null;
          })}
        </div>
      )}

      {categoryData && !loading && (
        <SituDocItemCategory data={categoryData} goBack={handleGoBack} />
      )}

      {loading && (
        <div className="d-flex justify-content-center text-secondary my-auto">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {!loading && businessSituation.id && (
        <button
          onClick={handleDownload}
          type="button"
          className="btn btn-success"
        >
          Descargar informe
        </button>
      )}
    </FullWidthContainer>
  );
}

const Headers = ({ title }) => {
  return (
    <div
      className="w-100 p-3 bg-gray text-white d-flex"
      style={{ borderRadius: "3px 3px 0 0" }}
    >
      <div className="w-75">
        <p className="mb-0 w-100 text-start fw-bold text-truncate">{title}</p>
      </div>
      <div className="w-25">
        <p className="mb-0 w-100 text-center fw-bold text-truncate">Opciones</p>
      </div>
    </div>
  );
};
