import React from "react";

export default function AuditTable() {
  return (
    <div className="d-flex flex-column">
      <h5 className="w-100 text-center mt-4 mb-3">Auditoría</h5>

      <div className="d-flex flex-column align-items-center justify-content-center gap-5 mb-3 border py-3 px-4 rounded">
        <div className="d-flex gap-2 align-items-center justify-content-between w-100">
          <p className="mb-0">
            Por auditar
            <span className="fw-bold ms-2">(click para auditar)</span>
          </p>

          <button type="button" className="btn btn-info btn-sm">
            <i className="far fa-edit fa-lg"></i>
          </button>
        </div>

        <div className="d-flex gap-2 align-items-center justify-content-between w-100">
          <p className="mb-0">Sin auditar</p>

          <button type="button" className="btn btn-secondary btn-sm">
            <i className="far fa-question-circle fa-lg"></i>
          </button>
        </div>

        <div className="d-flex gap-2 align-items-center justify-content-between w-100">
          <p className="mb-0">
            Conforme
            <span className="fw-bold ms-2">(click para ver comentarios)</span>
          </p>

          <button type="button" className="btn btn-success btn-sm">
            <i className="far fa-thumbs-up fa-md fa-lg"></i>
          </button>
        </div>

        <div className="d-flex gap-2 align-items-center justify-content-between w-100">
          <p className="mb-0">
            No conforme
            <span className="fw-bold ms-2">(click para ver comentarios)</span>
          </p>

          <button type="button" className="btn btn-danger btn-sm">
            <i className="far fa-thumbs-down fa-lg"></i>
          </button>
        </div>

        <div className="d-flex gap-2 align-items-center justify-content-between w-100">
          <p className="mb-0">
            Observaciones
            <span className="fw-bold ms-2">(click para ver comentarios)</span>
          </p>

          <button type="button" className="btn btn-warning btn-sm">
            <i className="far fa-eye fa-lg"></i>
          </button>
        </div>
      </div>
    </div>
  );
}
