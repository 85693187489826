import React from "react";
import { Link } from "react-router-dom";

import MobileSideBar from "components/layout/MobileSideBar";
import UserCard from "components/layout/UserCard";
import useAuth from "auth/useAuth";

import logo from "images/logo.jpg";

export default function Navbar() {
  const auth = useAuth();

  const handleLogout = () => {
    auth.logout();
  };

  return (
    <nav className="nav-top navbar navbar-expand-lg navbar-light bg-light fixed-top">
      <div className="container-fluid d-flex justify-content-between">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="Flex logo" width="50" />
        </Link>

        <h1 className="d-none d-lg-block text-center text-uppercase fs-4 mb-0">
          FLEX AUDITOR
        </h1>

        <div className="d-none d-lg-block">
          <UserCard handleLogout={handleLogout} />
        </div>

        <button
          className="d-lg-none btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#MobileSideBar"
          aria-controls="MobileSideBar"
        >
          <i className="fas fa-bars fa-lg"></i>
        </button>

        <MobileSideBar handleLogout={handleLogout} />
      </div>
    </nav>
  );
}
