import { useEffect } from 'react';
import useAuth from 'auth/useAuth';

export default function SSOLogin() {
  const auth = useAuth();

  useEffect(() => {
    const token = window.location.pathname.replace('/tokenuser/', '');
    console.log(token);
    auth.ssologin(token);
    //eslint-disable-next-line
  }, []);

  return (
    <main className="login-screen d-flex align-items-center justify-content-center">
      <h2>Validando datos...</h2>
    </main>
  );
}
