import useAuth from "auth/useAuth";
import React from "react";

export default function UserCard({ handleLogout }) {
  const auth = useAuth();

  return (
    <div className="d-flex align-items-center justify-content-center border px-3 py-1 rounded">
      <p
        className="text-center mb-0 text-truncate"
        style={{ maxWidth: "150px" }}
        title={auth.user?.userData.name}
      >
        {auth.user?.userData.name}
      </p>
      <button
        onClick={handleLogout}
        type="button"
        className="btn btn-danger btn-sm ms-2"
        data-bs-dismiss="offcanvas"
      >
        Salir
      </button>
    </div>
  );
}
