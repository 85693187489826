import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';

import { AuthProvider } from 'auth/authContext';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

import Login from 'pages/Login';
import Navbar from 'components/layout/Navbar';
import Sidebar from 'components/layout/Sidebar';

import SituacionDocumental from 'pages/SituacionDocumental';
import EdicionUsuario from 'pages/EdicionUsuario';
import SSOLogin from 'pages/SSOLogin';
import ChangePasswordRoute from './ChangePasswordRoute';
import ChangePassword from 'pages/ChangePassword';

export default function AppRouter() {
  return (
    <Router>
      <AuthProvider>
        <div className="app-content">
          <Navbar />
          <Sidebar />

          <Switch>
            <PublicRoute exact path="/" component={Login} />

            <ChangePasswordRoute
              exact
              path="/changepassword"
              component={ChangePassword}
            />

            <PublicRoute exact path="/auditor/" component={Login} />
            <PublicRoute exact path="/tokenuser/:token" component={SSOLogin} />

            <PrivateRoute
              exact
              path="/empresas"
              component={SituacionDocumental}
            />

            <PrivateRoute exact path="/gesUsuario" component={EdicionUsuario} />

            {/* <Route path="/404" component={NotFoundPage} /> */}
            <Route path="*">
              {/*<Redirect to="/404" />*/}
              <Redirect to="/auditor/" />
            </Route>
          </Switch>
        </div>
      </AuthProvider>
    </Router>
  );
}
