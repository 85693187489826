import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PUT } from "helpers/request";

import { useForm } from "hooks/useForm";

import { DefaultInput } from "templates/input";
import AlertMessage from "components/layout/AlertMessage";

const defaultValues = {
  user_email_text: "",
  user_pass_text: "",
  user_user_text: "",
  use_text_email: "",
  use_text_user: "",
  use_text_pass: "",
  use_text_name: "",
  use_text_lastname: "",
  use_text_phone: "",
  use_text_address: "",
};

export default function UsersForm({ data, id }) {
  const [values, handleInputChange, reset] = useForm({
    ...data,
    user_email_text: data.use_text_email,
    user_user_text: data.use_text_user,
    user_pass_text: "",
    use_text_name: data.use_text_name || "",
    use_text_lastname: data.use_text_lastname || "",
    use_text_phone: data.use_text_phone || "",
    use_text_address: data.use_text_address || "",
  });
  const [errorData, setError] = useState(defaultValues);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    setError(defaultValues);

    const correctData = {
      use_text_email: values.user_email_text,
      use_text_user: values.user_user_text,
      use_text_pass: values.user_pass_text || "",
      use_text_name: values.use_text_name,
      use_text_lastname: values.use_text_lastname,
      use_text_phone: values.use_text_phone,
      use_text_address: values.use_text_address,
    };

    const data = await PUT(
      defaultValues,
      correctData,
      "users",
      id,
      setError,
      reset
    );
    window.scrollTo({ top: 0, behavior: "smooth" });
    setSuccess(data ? data.status : 500);
    setLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccess(null);
    }, 3000);

    if (success === 201) {
      setTimeout(() => {
        history.push("/situacionDocumental");
      }, 3000);
    }

    return window.clearTimeout();
  }, [success, history]);

  const {
    user_email_text,
    user_user_text,
    user_pass_text,
    use_text_name,
    use_text_lastname,
    use_text_phone,
    use_text_address,
  } = values;

  return (
    <form
      onSubmit={handleSubmit}
      className="w-100 p-3 p-xl-5 d-flex flex-column mt-3"
      autoComplete="off"
    >
      <h2 className="w-100 text-center mb-3 mb-lg-5">Editar usuario</h2>

      {success && <AlertMessage status={success} id="1" />}

      <DefaultInput
        label="Nombres"
        type="text"
        value={use_text_name}
        inputName="use_text_name"
        onChange={handleInputChange}
        placeholder="Ingresa un nombre"
        error={errorData?.use_text_name}
        min="3"
      />

      <DefaultInput
        label="Apellidos"
        type="text"
        value={use_text_lastname}
        inputName="use_text_lastname"
        onChange={handleInputChange}
        placeholder="Ingresa un apellido"
        error={errorData?.use_text_lastname}
        min="3"
      />

      <DefaultInput
        label="Correo"
        type="email"
        value={user_email_text}
        inputName="user_email_text"
        onChange={handleInputChange}
        placeholder="Ingresa un email"
        error={errorData?.use_text_email}
        min="3"
      />

      <DefaultInput
        label="Usuario de acceso"
        type="text"
        value={user_user_text}
        inputName="user_user_text"
        onChange={handleInputChange}
        placeholder="Ingresa un usuario"
        error={errorData?.use_text_user}
        min="3"
        readOnly
      />

      <DefaultInput
        label="Contraseña"
        type="password"
        value={user_pass_text}
        inputName="user_pass_text"
        onChange={handleInputChange}
        placeholder="Ingresa una contraseña"
        error={errorData?.use_text_pass}
        required={false}
        min="3"
      />

      <DefaultInput
        label="Teléfono"
        type="number"
        value={use_text_phone}
        inputName="use_text_phone"
        onChange={handleInputChange}
        placeholder="Ingresa un número telefónico"
        error={errorData?.use_text_phone}
        min="3"
      />

      <DefaultInput
        label="Dirección"
        type="text"
        value={use_text_address}
        inputName="use_text_address"
        onChange={handleInputChange}
        placeholder="Ingresa una dirección"
        error={errorData?.use_text_address}
        min="3"
      />

      <button
        type="submit"
        className="btn btn-primary mt-4"
        disabled={loading ? true : false}
      >
        {loading ? (
          <>
            <i className="fas fa-circle-notch fa-spin me-2"></i> Cargando
          </>
        ) : (
          "Editar"
        )}
      </button>
    </form>
  );
}
