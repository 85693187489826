import React, { useEffect, useState } from "react";
import { GET } from "helpers/request";
import useAuth from "auth/useAuth";

import FullWidthContainer from "components/layout/FullWidthContainer";
import UsersForm from "components/Forms/UsersForm";

export default function EdicionUsuario() {
  const auth = useAuth();
  const [data, setData] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetcher = async () => {
      const data = await GET("users", auth.user.userData.id);
      setData(data);
      setLoading(false);
    };

    fetcher();
  }, [auth]);

  if (loading) {
    return (
      <div className="custom-container">
        <div className="w-100 h-100 d-flex flex-column align-items-center card p-3 p-xl-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <FullWidthContainer>
      <UsersForm data={data} id={auth.user.userData.id} />
    </FullWidthContainer>
  );
}
