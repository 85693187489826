import React, { useRef } from "react";

import useAudit from "hooks/useAudit";

import { DefaultTextarea } from "templates/input";

export default function AuditModal({ recordId, recordName, setRegistros }) {
  const closeBtnRef = useRef();

  const { loading, comment, setComment, commentRequired, audit, resetModal } =
    useAudit(recordId, setRegistros, closeBtnRef);

  return (
    <div
      className="modal fade"
      id={`auditModal-${recordId}`}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="auditModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-light">
          <div className="modal-header">
            <h5 className="modal-title" id="auditModalLabel">
              Auditar documento
            </h5>
            <button
              disabled={loading ? true : false}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetModal}
            ></button>
          </div>
          <div className="modal-body">
            <p className="text-start">
              <span className="fw-bold me-2">Documento:</span>
              {recordName}
            </p>

            <DefaultTextarea
              label="Descripción"
              value={comment}
              inputName="comment"
              onChange={({ target }) => setComment(target.value)}
              placeholder="Deja un comentario..."
              error={commentRequired}
              required={false}
            />
          </div>
          <div className="modal-footer">
            <button
              disabled={loading ? true : false}
              type="button"
              className="btn btn-success"
              onClick={() => audit("1")}
            >
              Conforme
            </button>

            <button
              disabled={loading ? true : false}
              type="button"
              className="btn btn-danger"
              onClick={() => audit("2")}
            >
              No conforme
            </button>

            <button
              disabled={loading ? true : false}
              type="button"
              className="btn btn-warning"
              onClick={() => audit("3")}
            >
              Observaciones
            </button>

            <button
              ref={closeBtnRef}
              disabled={loading ? true : false}
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={resetModal}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
