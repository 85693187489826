import { POST_LOGIN, POST_REFRESH } from 'helpers/request';
import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';

import CloseToExpirationModal from './CloseToExpirationModal';
import axios from 'axios';

const BASE_BASE_URL = process.env.REACT_APP_BASE_BASE_URL;

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [enableRefresh, setEnableRefresh] = useState(false);
  const [user, setUser] = useState(
    sessionStorage.getItem('userData')
      ? { userData: { ...JSON.parse(sessionStorage.getItem('userData')) } }
      : null
  );
  const history = useHistory();

  const toggleExpirationModalRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  let time = new Date();
  time.setMinutes(time.getMinutes() + 55);

  const { pause, restart, start } = useTimer({
    autoStart: false,
    expiryTimestamp: time,
    onExpire: () => {
      setIsModalOpen(true);
      toggleExpirationModalRef.current.click();
    }
  });

  const login = async (email, password) => {
    try {
      const res = await POST_LOGIN(email, password);

      if (!res.data.body.hasTags?.includes('auditor')) {
        return 'Este usuario no tiene acceso';
      }

      sessionStorage.setItem('access_token', res.data.body.access_token);
      sessionStorage.setItem('refresh_token', res.data.body.refresh_token);
      sessionStorage.setItem(
        'userData',
        JSON.stringify(res.data.body.userData)
      );

      // email user enconde base 64
      sessionStorage.setItem('emuecb', btoa(email));

      // user last Login (now)
      sessionStorage.setItem('dull', btoa(new Date().getTime()));

      setEnableRefresh(true);
      start();

      setUser(res.data.body);
    } catch (error) {
      console.log(error);
      return 'Acceso denegado, revise su usuario, contraseña y conectividad al internet';
    }
  };

  const ssologin = async (token) => {
    try {
      const params = new URLSearchParams();
      params.append('d', token);

      const res = await axios.post(`${BASE_BASE_URL}user/ssouser`, params, {
        headers: {
          Authorization: 'Basic dGVzdGNsaWVudDp0ZXN0c2VjcmV0'
        }
      });

      if (!res.data.body.hasTags?.includes('auditor')) {
        setUser(null);
        throw new Error('Sin los accesos requerido');
      }

      sessionStorage.setItem('access_token', res.data.body.access_token);
      sessionStorage.setItem('refresh_token', res.data.body.refresh_token);
      sessionStorage.setItem(
        'user-data',
        JSON.stringify(res.data.body.userData)
      );

      // email user enconde base 64
      sessionStorage.setItem('emuecb', btoa(res.data.body.userData.user));

      // user last Login (now)
      sessionStorage.setItem('dull', btoa(new Date().getTime().toString()));

      setTimeout(() => {
        setUser(res.data.body);
      }, 1000);
    } catch (error) {
      console.log(error);
      setUser(null);
    }
  };

  const refreshToken = useCallback(async () => {
    setEnableRefresh(false);

    const thisLoginDate = new Date().getTime();
    const refreshToken = sessionStorage.getItem('refresh_token');

    let lastLoginDate = sessionStorage.getItem('dull');
    lastLoginDate = atob(lastLoginDate);

    let userData = sessionStorage.getItem('userData');
    userData = JSON.parse(userData);

    let email = sessionStorage.getItem('emuecb');

    // if no email in storage, need to login
    if (!email) return;

    // set email as decoded string
    email = atob(email);

    // if email, need to compare last token date against token now, time cant be greater than 3600 seconds or 1 hour
    const timeDifference = (thisLoginDate - lastLoginDate) / 1000;

    // if token expired, require login
    if (timeDifference > 3600) return history.push('/');

    // set context user with prev userData info from storage to allow instant redirect to home while refresh full data with the API call
    setUser({ userData });

    try {
      const res = await POST_REFRESH(refreshToken, email);

      if (!res.data.body.hasTags?.includes('auditor')) {
        setUser(null);
      }

      setUser(res.data.body);

      sessionStorage.setItem('access_token', res.data.body.access_token);
      sessionStorage.setItem('refresh_token', res.data.body.refresh_token);
      sessionStorage.setItem(
        'userData',
        JSON.stringify(res.data.body.userData)
      );

      // email user enconde base 64
      sessionStorage.setItem('emuecb', btoa(email));

      // user last Login (now)
      sessionStorage.setItem('dull', btoa(new Date().getTime()));

      setEnableRefresh(true);

      // close refresh modal
      const modalRefresh = document.getElementById('CloseToExpiration');
      if (modalRefresh.className.includes('show')) {
        toggleExpirationModalRef.current.click();

        // set modal open state to false
        setIsModalOpen(false);
      }

      // toggle loading false in refresh modal
      setIsRefreshing(false);
    } catch (error) {
      console.log(error);
    }
  }, [history]);

  const logout = () => {
    const modalRefresh = document.getElementById('CloseToExpiration');
    if (modalRefresh.className.includes('show')) {
      toggleExpirationModalRef.current.click();
    }

    setUser(null);

    setEnableRefresh(false);

    pause();

    sessionStorage.clear();
  };

  const isLogged = () => {
    return !!user;
  };

  useEffect(() => {
    refreshToken();
  }, [refreshToken]);

  useEffect(() => {
    if (enableRefresh) {
      const time = new Date();
      time.setMinutes(time.getMinutes() + 55);
      restart(time);
    }

    return setEnableRefresh(false);

    //eslint-disable-next-line
  }, [refreshToken, enableRefresh]);

  useEffect(() => {
    let autoCloseSession;

    function startAutoCloseSession() {
      autoCloseSession = setTimeout(() => {
        setIsModalOpen(false);
        logout();
      }, 60000);
    }

    function stopAutoCloseSession() {
      window.clearTimeout(autoCloseSession);
    }

    if (isModalOpen) {
      startAutoCloseSession();
    }

    return stopAutoCloseSession;

    //eslint-disable-next-line
  }, [isModalOpen]);

  return (
    <AuthContext.Provider value={{ user, login, ssologin, logout, isLogged }}>
      <button
        ref={toggleExpirationModalRef}
        type="button"
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#CloseToExpiration"
      ></button>

      <CloseToExpirationModal
        isModalOpen={isModalOpen}
        refresh={refreshToken}
        logout={logout}
        isRefreshing={isRefreshing}
        setIsRefreshing={setIsRefreshing}
      />

      {children}
    </AuthContext.Provider>
  );
};
