import { useState } from "react";
import { adminUrl, headerToken } from "api/api";

export default function useAudit(recordId, setRegistros, closeBtnRef) {
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [commentRequired, setCommentRequired] = useState(null);

  const audit = async (status) => {
    setLoading(true);

    setCommentRequired(null);

    if ((status === "2" || status === "3") && comment.length === 0) {
      setLoading(false);
      setCommentRequired("Debe ingresar un comentario");
      return;
    }

    try {
      await adminUrl.post(
        "auditar",
        {
          rec_int_conformidad: status,
          rec_text_obsaud: comment,
          rec_int_id: recordId,
        },
        headerToken()
      );

      setRegistros((prevData) =>
        prevData.map((item) =>
          item.rec_int_id === recordId
            ? { ...item, rec_int_conformidad: status }
            : item
        )
      );

      setCommentRequired(null);
      setLoading(false);

      closeBtnRef.current.click();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const resetModal = () => {
    setLoading(false);
    setComment("");
    setCommentRequired(null);
  };

  return {
    loading,
    comment,
    setComment,
    commentRequired,
    audit,
    resetModal,
  };
}
