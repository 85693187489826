import React from "react";

import { PDF_DWNLD_GET } from "helpers/request";

import AuditModal from "components/situ-doc-components/AuditModal";

import "styles/RecordRow.css";

export default function RecordRow({ item, setRegistros }) {
  const isPdf = item.rec_text_type.split("/")[1].includes("pdf");

  const handleForceDownload = async () => {
    await PDF_DWNLD_GET("records_lectura", item.rec_text_name, item.rec_int_id);
  };

  return (
    <>
      <div
        key={item.rec_int_id}
        className="w-100 d-flex bg-light p-2 rounded border mb-2 align-items-center"
      >
        <span className="fw-bold px-2 text-center me-2">{item.rec_int_id}</span>

        <div className="d-flex rec-row-title">
          <p className="mb-0 w-100 text-truncate">
            {isPdf && <i className="far fa-file-pdf me-2"></i>}
            <span
              className={
                item.rec_date_deletion_date && "text-decoration-line-through"
              }
            >
              {item.rec_text_name}
            </span>
            {item.rec_date_deletion_date && (
              <span className="text-decoration-none ms-2 text-danger">
                (Eliminado)
              </span>
            )}
          </p>
        </div>

        <div className="rec-row-date">
          <p className="mb-0 w-100 text-truncate text-center">
            <i className="far fa-calendar-alt me-2"></i>
            {item.rec_date_creation_date?.split(" ")[0]}
          </p>
        </div>

        <div className="d-flex align-items-center gap-2 justify-content-start rec-row-options">
          <button
            title="Descargar"
            type="button"
            readOnly
            style={{ pointerEvents: "none" }}
            className={`btn btn-sm ${
              (item.rec_int_status === "0" && "btn-success") ||
              (item.rec_int_status === "1" && "btn-warning") ||
              (item.rec_int_status === "2" && "btn-danger") ||
              (item.rec_int_status === "3" && "btn-secondary")
            }`}
          >
            {item.rec_int_status === "0" && (
              <i className="far fa-check-circle fa-lg"></i>
            )}
            {item.rec_int_status === "1" && (
              <i className="far fa-question-circle fa-md fa-lg"></i>
            )}
            {item.rec_int_status === "2" && (
              <i className="far fa-times-circle fa-lg"></i>
            )}
            {item.rec_int_status === "3" && (
              <i className="far fa-folder-open fa-lg"></i>
            )}
          </button>

          {item.rec_int_status === "0" && item.rec_int_conformidad === "0" ? (
            <button
              data-bs-toggle="modal"
              data-bs-target={`#auditModal-${item.rec_int_id}`}
              type="button"
              className="btn btn-info btn-sm"
            >
              <i className="far fa-edit"></i>
            </button>
          ) : (
            <button
              data-bs-toggle="collapse"
              data-bs-target={`#collapseRec-${item.rec_int_id}`}
              aria-expanded="false"
              aria-controls={`collapseRec-${item.rec_int_id}`}
              type="button"
              style={{
                pointerEvents:
                  item.rec_int_conformidad === "0" ? "none" : "unset",
              }}
              className={`btn btn-sm ${
                (item.rec_int_conformidad === "0" && "btn-secondary") ||
                (item.rec_int_conformidad === "1" && "btn-success") ||
                (item.rec_int_conformidad === "2" && "btn-danger") ||
                (item.rec_int_conformidad === "3" && "btn-warning")
              }`}
            >
              {item.rec_int_conformidad === "0" && (
                <i className="far fa-question-circle fa-lg"></i>
              )}
              {item.rec_int_conformidad === "1" && (
                <i className="far fa-thumbs-up fa-lg"></i>
              )}
              {item.rec_int_conformidad === "2" && (
                <i className="far fa-thumbs-down fa-md fa-lg"></i>
              )}
              {item.rec_int_conformidad === "3" && (
                <i className="far fa-eye fa-lg"></i>
              )}
            </button>
          )}

          {item.rec_int_status === "0" && (
            <button
              onClick={handleForceDownload}
              type="button"
              className="btn btn-primary btn-sm"
              title="Descargar"
            >
              <i className="fas fa-download"></i>
            </button>
          )}

          <AuditModal
            recordId={item.rec_int_id}
            recordName={item.rec_text_name}
            setRegistros={setRegistros}
          />
        </div>
      </div>

      <div
        className="collapse mb-4 w-100"
        id={`collapseRec-${item.rec_int_id}`}
      >
        <div className="card card-body">
          <h6 className="text-start fw-bold">Comentarios de auditoría:</h6>
          <p className="mb-0 text-justify">{item.rec_text_obsaud}</p>
        </div>
      </div>
    </>
  );
}
