import axios from "axios";

const BASE_URL = process.env.REACT_APP_ADMINISTRATION_BASE_URL;
const BASE_BASE_URL = process.env.REACT_APP_BASE_BASE_URL;

const config = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${
        token || sessionStorage.getItem("access_token")
      }`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
};

export async function POST(values, module, setError, reset, token) {
  const URL = BASE_URL + module;

  // para enviar la data en formato x-www-form-urlencoded
  const params = new URLSearchParams();
  for (const key in values) {
    if (values[key] !== undefined) {
      params.append(key, values[key]);
    }
  }

  try {
    const { data } = await axios.post(URL, params, config(token));
    reset();
    return data;
  } catch (error) {
    console.log(error.response.data.messages);
    return setError(error.response?.data.messages);
  }
}

export async function GET(module, id = null, token) {
  let URL;

  if (id) {
    URL = BASE_URL + module + "/" + id;
  } else {
    URL = BASE_URL + module;
  }

  try {
    const { data } = await axios.get(URL, config(token));
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function DELETE(module, id, token) {
  if (!id) {
    return "Necesitas enviar un id";
  }

  const URL = BASE_URL + module + "/" + id;

  try {
    const data = await axios.delete(URL, config(token));
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function PUT(
  defaultValues,
  values,
  module,
  id,
  setError,
  reset,
  token
) {
  const URL = BASE_URL + module + "/" + id;

  let editContent = {};

  for (const key in defaultValues) {
    editContent = { ...editContent, [key]: values[key] };
  }

  // para enviar la data en formato x-www-form-urlencoded
  const params = new URLSearchParams();
  for (const key in editContent) {
    if (values[key] !== undefined) {
      params.append(key, values[key]);
    }
  }

  try {
    const { data } = await axios.put(URL, params, config(token));
    reset();
    return data;
  } catch (error) {
    console.log(error.response?.data.messages);
    return setError(error.response?.data.messages);
  }
}

export async function READ(module, id, token) {
  const URL = BASE_URL + module + "/" + id;

  try {
    await axios.put(URL, null, config(token));
  } catch (error) {
    console.log(error);
  }
}

export async function POST_LOGIN(email, password) {
  let URL = BASE_BASE_URL + "user/login";

  let user = process.env.REACT_APP_OAUTH2_CLIENT;
  let pass = process.env.REACT_APP_OAUTH2_SECRET;

  const base64 = btoa(user + ":" + pass);

  const config = {
    headers: {
      Authorization: `Basic ${base64}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const data = new URLSearchParams();
  data.append("grant_type", "password");
  data.append("username", email);
  data.append("password", password);

  try {
    const res = await axios.post(URL, data, config);
    return res;
  } catch (error) {
    console.log(error);
  }
}

export async function POST_REFRESH(refreshToken, email) {
  let URL = BASE_BASE_URL + "user/login";

  let user = process.env.REACT_APP_OAUTH2_CLIENT;
  let pass = process.env.REACT_APP_OAUTH2_SECRET;

  const base64 = btoa(user + ":" + pass);

  const config = {
    headers: {
      Authorization: `Basic ${base64}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const data = new URLSearchParams();
  data.append("grant_type", "refresh_token");
  data.append("refresh_token", refreshToken);
  data.append("username", email);

  try {
    const res = await axios.post(URL, data, config);
    return res;
  } catch (error) {
    console.log(error);
  }
}

export async function GET_RECOVER(ruc, setSuccess, setError, setRecover) {
  let URL = BASE_BASE_URL + "user/recover/" + ruc;

  let user = process.env.REACT_APP_OAUTH2_CLIENT;
  let pass = process.env.REACT_APP_OAUTH2_SECRET;

  const base64 = btoa(user + ":" + pass);

  const config = {
    headers: {
      Authorization: `Basic ${base64}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  try {
    const res = await axios.get(URL, config);
    setSuccess(res.data.mensaje);
    setRecover(false);
  } catch (error) {
    console.log(error);
    setError(error.response.data.mensaje);
  }
}

export async function PDF_GET(module, id = null, token) {
  let URL;

  if (id) {
    URL = BASE_URL + module + "/" + id;
  } else {
    URL = BASE_URL + module;
  }
  const config = {
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${
        token || sessionStorage.getItem("access_token")
      }`,
    },
  };

  try {
    const { data } = await axios.get(URL, config);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function PDF_DWNLD_GET(
  module,
  name = "archivo.pdf",
  id = null,
  token
) {
  let URL;
  if (id) {
    URL = BASE_URL + module + "/" + id;
  } else {
    URL = BASE_URL + module;
  }
  const config = {
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${
        token || sessionStorage.getItem("access_token")
      }`,
    },
  };

  try {
    const { data } = await axios.get(URL, config);
    const href = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", name); //or any other extension
    document.body.appendChild(link);
    link.click();
    return data;
  } catch (error) {
    console.log(error);
  }
}
